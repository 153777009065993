import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import "bootstrap";
import "./fontawesome";

import UIBindings from "./ui-bindings";
import { Forms } from "./form";
import { ExternalLinks } from "./externallinks";
import { PageTimer } from "./pagetimer";
import { ShareHandler } from "./share";
import "./cookies";


(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
(new ShareHandler()).build();

const forms = new Forms();
forms.validate();
forms.steps();
forms.matrixField();
forms.hiddenFields();
forms.fileInput();


const UIBinding = new UIBindings();
UIBinding.formSubmitButton();
UIBinding.magnificPopup();
UIBinding.slickCarourel();
UIBinding.bootstrap();
UIBinding.datepicker();
// UIBinding.menuShrink();
UIBinding.btnAnchor();