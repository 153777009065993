import $ from "jquery";
import "parsleyjs";
export class Forms {
    forms = null;
    parsleyParams = {
        excluded: Parsley.options.excluded + ', input:hidden, select:hidden, textarea:hidden',
        errorClass: 'is-invalid',
        successClass: 'is-valid',
        errorsWrapper: '<div class="invalid-feedback leading-tight"></div>',
        errorTemplate: '<span></span>',
        errorsContainer: function (Field) {
            if (Field.element.type === 'checkbox' || Field.element.type === 'radio') {
                return Field.$element.closest('.input').find('> :visible:last');
            }
        },
        classHandler: function (Field) {
            if (Field.element.type === 'checkbox' || Field.element.type === 'radio') {
                return Field.$element.closest('.input').find('> :visible input');
            }
        }
    }

    constructor($selector = $('form')) {
        if ($selector.length > 0) {
            this.forms = $selector;
        }
    }

    validate() {
        if (this.forms) {
            this.forms.parsley(this.parsleyParams)
        }
    }

    steps() {

        var $sections = $('.form-section');
        var next = $('.form-navigation .next');
        var prev = $('.form-navigation .previous');
        var saveBtn = $('.form-navigation .save');
        var reviewBtn = $('.form-navigation .review');
        var step = $('#application-form').data('step');
        prev.data('index', false);
        next.data('index', false);
        saveBtn.data('index', false);

        $('input').each(function (index, data) {
            var nameAttr = $(this).attr('name');
            if (nameAttr) {
                // var name = nameAttr.replace('[fields]', '');
                // $(this).attr('name', name);
                // console.log(nameAttr);
            }
        });
        $('select').each(function (index, data) {
            var nameAttr = $(this).attr('name');
            if (nameAttr) {
                // var name = nameAttr.replace('[fields]', '');
                // $(this).attr('name', name);
                // console.log(nameAttr);
            }
        });

        console.log(step);
        function navigateTo(index) {
            // Mark the current section with the class 'current'
            $sections
                .removeClass('d-block')
                .addClass('d-none')
                .eq(index)
                .addClass('d-block');
            // Show only the navigation buttons that make sense for the current section:
            prev.toggle(index > 0);
            var atTheEnd = index >= $sections.length - 1;
            next.toggle(!atTheEnd);
            reviewBtn.toggle(atTheEnd);
        }

        function curIndex() {
            // Return the current index by looking at which section has the class 'current'
            return $sections.index($sections.filter('.d-block'));
        }

        // Previous button is easy, just go back
        prev.click(function () {
            var result = null,
                tmp = [];
            var items = location.search.substr(1).split("&");
            for (var index = 0; index < items.length; index++) {
                tmp = items[index].split("=");
                result = decodeURIComponent(tmp[1] - 1);
            };
            console.log(tmp);

            var url = window.location.href
            var regEx = /([?&]step)=([^#&]*)/g;
            var newurl = url.replace(regEx, '$1=' + result);
            console.log(newurl)
            window.location.href = newurl;
            $('html, body').animate({ scrollTop: 0 }, 'fast');

        });

        reviewBtn.click(function (e) {
            console.log("reviewBtn click...");

            e.preventDefault();
            let form = $(this).closest('form');

            // form.parsley(this.parsleyParams).whenValidate({
            //     group: 'block-' + curIndex()
            // });
            document.getElementById("application-form").submit();
            console.log("reviewBtn", $(".form-submit"));


        });

        // Prepare sections by setting the `data-parsley-group` attribute to 'block-0', 'block-1', etc.
        $sections.each(function (index, section) {
            $(section).find('.input [required]').attr('data-parsley-group', 'block-' + index);
        });

        saveBtn.click(function (e) {
            e.preventDefault();
            console.log("saveBtn click...");

            $("#tab-errors").addClass("d-none");
            var numInputs = 0
            var numTab = $("#currentTab").attr('data-tab');
            var is_incomplete = 0;
            if (numTab == 1) {
                var matrixBlock = $(".matrixblock").length;
                if (matrixBlock < 2) {
                    is_incomplete = 1;
                    $("#tab-errors").removeClass("d-none");
                }
            }

            if (is_incomplete == 0) {
                $("#tab-errors").addClass("d-none");
                $(".form-submit").submit();
                let index = $(this).data('index');
                navigateTo(index !== false ? index : curIndex());

            }

        });

        // Start at the beginning
        navigateTo(step);

    }

    matrixField() {
        console.log('matrixField...')

        var forms = $('form');
        var index = 1;
        forms.on('click', '.addButton', function () {
            var matrixBlocks = $(this).closest('.form-group').find('.matrixblock');
            var $template = $(this).closest('.form-group').find('#fields-fields-blockTemplate'),
                $clone = $("<div></div>");
                $clone.html($template.html());
                $clone.data('index', index)
                    .addClass('clone matrixblock bg-light p-4 mb-4')
                    .insertBefore($template);

            var attributesToChange = ['name', 'data-name', 'data-input-filename', 'value']

            $.each(attributesToChange, function (i, attrName) {
                console.log('attrName', attrName)
                // Update the name attributes
                $clone.find(`[${attrName}]`).each(function (e) {
                    var oldValue = this.getAttribute(attrName);
                    if (oldValue.match('blockId')) {
                        var newValue = oldValue.replace('blockId', 'new' + index);
                        // name = name.replace('[fields]', '');
                        $(this).attr(attrName, newValue);
                        console.log('oldValue', oldValue, 'newValue', newValue)
                    }

                })
            })

            $('.datepicker').datepicker({
                minViewMode: "months",
                format: 'dd/mm/yyyy'
            });
            index = index + 1;

        })

        forms.on('click', '.removeButton', function () { // Remove button click handler
            var $row = $(this).parents('.matrixblock');
            var form = $(this).closest('form');
            index = form.find('.matrixblock').length - 1;
            $row.remove();
            return false;
        })
    }

    hiddenFields() {
        console.log('hiddenFields...')

        function toggleCostSharing (value) {
            var element = document.getElementById('fields-fields-costSharingExplanation-field');
            if (value === "costSharingScholarship") {

                element.classList.remove("d-none");
                var nameAttr = $("#fields-fields-costSharingExplanation").attr('name');
                if (nameAttr) {
                    // var name = nameAttr.replace('[fields]', '');
                    // $("#fields-fields-costSharingExplanation").attr('name', name);
                }
            }
            else {
                element.classList.add("d-none");
            }
        }

        if ($('[name="fields[scholarship]"]').length && $('[name="fields[scholarship]"]').length) {
            toggleCostSharing($('[name="fields[scholarship]"]').val());
        }

        $('select[name="fields[scholarship]"]').on('change', function () {
            toggleCostSharing(this.value);
        })

        function togglePhdFields (value) {
            var element = document.getElementById('fields-fields-supervisor-field');
            var element2 = document.getElementById('fields-fields-emailAddressOfTheSupervisor-field');
            var element3 = document.getElementById('fields-fields-phdInCotutelle-field');
            var element4 = document.getElementById('fields-fields-supervisorInSouthAfrica-field');
            var element5 = document.getElementById('fields-fields-emailAddressOfTheSupervisorInSouthAfrica-field');
            var element6 = document.getElementById('fields-fields-universityOfTheSupervisorInSouthAfrica-field');

            if (value === "phd") {
                element.classList.remove("d-none");
                element2.classList.remove("d-none");
                element3.classList.remove("d-none");
                element4.classList.remove("d-none");
                element5.classList.remove("d-none");
                element6.classList.remove("d-none");
            } else {
                element.classList.add("d-none");
                element2.classList.add("d-none");
                element3.classList.add("d-none");
                element4.classList.add("d-none");
                element5.classList.add("d-none");
                element6.classList.add("d-none");
            }
        }

        if ($('select[name="fields[studyLevel]"]').length) {
            togglePhdFields($('select[name="fields[studyLevel]"]').val())
        }


        $('select[name="fields[studyLevel]"]').on('change', function () {
            togglePhdFields(this.value);
        });


        $(".create-account").click(function () {
            $('input[id="customCheck1"]').prop("value", 'I agree');
            var agreeTerms = document.getElementById("customCheck1");
            var agreeTermsCTA = document.getElementById("agreeTerms");
            var loginCTA = document.getElementById("loginCTA");
            var hiddenForm = document.getElementById('hiddenForm');
            var registerForm = document.getElementById('registerForm');
            if (agreeTerms.value === 'I agree') {
                hiddenForm.classList.remove("d-none");
                agreeTermsCTA.classList.add("d-none");
                loginCTA.classList.add("d-none");
                registerForm.classList.remove("col-md-6");
                registerForm.classList.add("col-md-12");
            } else {
                hiddenForm.classList.add("d-none");
            }
        });
    }

    fileInput() {
        console.log('fileInput...')

        $(document).on('change', 'input[type="file"]', function () {
            console.log('input[type="file"] change...')

            var input = $(this);
            var str = this.value;
            var strIndexOf = str.replace(/\\/g, '/');
            var filename = strIndexOf.split("/").pop();
            var parent = input.closest('[data-fileinput]');


            parent.addClass(this.value ? 'fileinput-exists' : 'fileinput-new').removeClass(this.value ? 'fileinput-new' : 'fileinput-exists');
            var inputName = this.name;
            // var inputElement = "fields[fields]"+inputName.substr(6);
            // console.log('inputName', inputName, 'inputElement', inputElement)
            var selector = `[data-input-filename="${inputName}"]`;
            console.log('selector', selector)
            $(selector).text(filename);
            //$(`[name="${this.name}[]"]`).val('');
        });

        $(document).on('click', '[data-file-dismiss]', function () {
            console.log('[data-file-dismiss] click...')

            var parent = $(this).closest('[data-fileinput]');
            parent.addClass('fileinput-new').removeClass('fileinput-exists');
            $(`[data-input-filename="${this.name}"]`).text('');
            parent.find(`input[name="${this.name}"]`).val('');
        })
    }
}