import $ from "jquery";
import "magnific-popup";
import "slick-carousel";
import "bootstrap-datepicker";

export default class UIBindings {

    formSubmitButton() {
        $(document).on('submit', 'form', function () {
            var allButtons = $(this).find('[data-submit-text]');

            allButtons.each(function () {
                let submitText = $(this);
                let submittingText = $(this).closest('button').find('[data-submitting-text]');
                let btn = submitText.closest('button');
                
                submittingText.removeClass('d-none');
                submitText.addClass('d-none');
                btn.prop('disabled', true);

                console.log("[data-submitting-text]", $(this).text())
            });


        });
    }

    menuShrink (){
        $(document).ready(function() {
          $(window).on("scroll", function() {
            if ($(window).scrollTop() >= 20) {
              $(".navbar").removeClass("navbar-expand-lg");
              $(".navbar-brand").addClass("py-2");
              $(".user-nav").removeClass("position-lg-absolute");
              $(".navbar-brand img").addClass("w-75");
            } else {
              $(".navbar").addClass("navbar-expand-lg");
              $(".navbar-brand").removeClass("py-2");
              $(".user-nav").addClass("position-lg-absolute");
              $(".navbar-brand img").removeClass("w-75");
            }
          });
        });
    }

    magnificPopup () {
        let image = $('[mfp-type="image"]');
        let gallery = $('[mfp-type="gallery"]');

        if (image.length > 0) {
            image.magnificPopup({type: "image"});
        }
        if (gallery.length > 0) {
            gallery.magnificPopup({
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: true,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    tCounter: '%curr% of %total%',
                    preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
                },
                zoom: {
                    enabled: true,
                    duration: 500 // don't foget to change the duration also in CSS
                }
            });
        }
    }

    slickCarourel() {
        let carousel = $('[data-slick]');
        let customPaging = function(slide, i){
            return $('<span class="cursor-pointer px-1"><i class="fas fa-circle fa-xs slick-dot active"/><i class="far fa-circle fa-xs slick-dot"/></span>');
        }
        if (carousel.length > 0) {
            carousel.slick({customPaging: customPaging});
        }
    }

    bootstrap () {
    	$('[data-toggle="tooltip"]').tooltip();
        $('.custom-file').on('change','.custom-file-input',function(){
            //get the file name
            var str = this.value;
            var strIndexOf = str.replace(/\\/g, '/');
            var filename = strIndexOf.split("/").pop();
            //replace the "Choose a file" label
            $(this).next('.custom-file-label').html(filename);
        });
    }

    btnAnchor(){
        $('.anchor').on("click", function() {
          var anchor = $(this).data('target');  
          var anchorTarget =  $(anchor);
          if (anchorTarget.length > 0 && $('.navbar').hasClass('navbar-expand-md')) {
                $('html, body').animate({
                    scrollTop: anchorTarget.offset().top - 170
                },1000);
                return false; 
            } else {
                $('html, body').animate({
                    scrollTop: anchorTarget.offset().top - 120
                }, 1000);
                return false;
          };
           
        });
    }

    datepicker(){
        $('.dateOfBirth-datepicker').datepicker({
            minViewMode: "days",
            format: 'dd/mm/yyyy'
        });
        $('.datepicker').datepicker({
            minViewMode: "months",
            format: 'dd/mm/yyyy'
        });
        
        
    }
}

   