import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faEdit as falEdit, faQuoteLeft as falQuoteLeft, faQuoteRight as falQuoteRight, faSave as falSave, faTimes, faFile,  faFileWord, faFilePowerpoint, faFileImage, faFileSpreadsheet, faFileArchive, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch, faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faChevronLeft, faChevronDown, faCircle as fasCircle, faSearch as fasSearch, faChevronUp as fasChevronUp, faLink as fasLink, faDownload as fasDownload, faEdit as fasEdit, faSpinner as fasSpinner, faUserCircle as fasUserCircle, faMinusCircle as fasMinusCircle, faCalendarAlt as fasCalendarAlt, faUser as fasUser, faVenusMars as fasVenusMars, faBirthdayCake as fasBirthdayCake, faFlagAlt as fasFlagAlt, faMapMarkerAlt as fasMapMarkerAlt, faGlobeAfrica as fasGlobeAfrica, faUserCrown as fasUserCrown, faPhoneAlt as fasPhoneAlt, faPlusCircle as fasPlusCircle} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faGooglePlusG, faInstagram} from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(falEdit, falQuoteLeft, falQuoteRight, falSave,faFile,faTimes, faFileWord, faFilePowerpoint, faFileImage, faFileSpreadsheet, faFileArchive, faFilePdf);
// regular
library.add(farSearch, farCircle);
// solid
library.add(faChevronRight, faChevronLeft, faChevronDown, fasCircle, fasSearch, fasChevronUp, fasLink, fasDownload, fasEdit, fasSpinner, fasUserCircle, fasMinusCircle,fasCalendarAlt, fasUser, fasVenusMars, fasBirthdayCake, fasFlagAlt, fasMapMarkerAlt, fasGlobeAfrica, fasUserCrown, fasPhoneAlt, fasPlusCircle);
// brands
library.add(faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faGooglePlusG, faInstagram);

dom.i2svg();
dom.watch();