import $ from "jquery";
import Cookies from 'js-cookie';

$(window).on('load', function() {
    var cookieValue = Cookies.get('cookies');
    if (!cookieValue) {
    	$('#cookies-box').modal('show');
    }
    $('#cookies-box .accept-btn').click(function() {
      Cookies.set('cookies', '1', { expires: 365 });
      $('#cookies-box').modal('hide');
    });
});